import React from "react";
import Navbar from "./components/Navbar/Navbar.js";
import Navbar_categories_en from "./components/Navbar/Navbar categories en.js";
import Navbar_categories_el from "./components/Navbar/Navbar categories el.js";
import {BrowserRouter as Router, Routes,Route,} from "react-router-dom";
import Beer_Greek from "./pages/greek_beer.js";
import Breakfast_Greek from "./pages/greek_breakfast.js";
import Choco_Greek from "./pages/greek_choco.js"
import Coffee_Greek from "./pages/greek_coffee.js";
import Distillates_Greek from "./pages/greek_distillates.js"
import Juices_Greek from "./pages/greek_juices.js"
import Pizza_Greek from "./pages/greek_pizza.js"
import Soft_Drinks_Greek from "./pages/greek_soft_drinks.js"
import Spirits_Greek from "./pages/greek_spirits.js"
import Sweets_Greek from "./pages/greek_sweets.js";
import Tea_Greek from "./pages/greek_tea.js"
import Toast_Greek from "./pages/greek_toast.js"
import Wine_Greek from "./pages/greek_wine.js"
import Yoghurt_Greek from "./pages/greek_yoghurt.js"
import Traditional_Snacks_Greek from "./pages/greek_traditional_snacks.js"
import Beverages_Greek from "./pages/greek_beverages.js"
import Waffles_Greek from "./pages/greek_waffles.js"

import Beer_English from "./pages/english_beer.js";
import Breakfast_English from "./pages/english_breakfast.js";
import Choco_English from "./pages/english_choco.js"
import Coffee_English from "./pages/english_coffee.js";
import Distillates_English from "./pages/english_distillates.js"
import Juices_English from "./pages/english_juices.js"
import Pizza_English from "./pages/english_pizza.js"
import Soft_Drinks_English from "./pages/english_soft_drinks.js"
import Spirits_English from "./pages/english_spirits.js"
import Sweets_English from "./pages/english_sweets.js";
import Tea_English from "./pages/english_tea.js"
import Toast_English from "./pages/english_toast.js"
import Wine_English from "./pages/english_wine.js"
import Yoghurt_English from "./pages/english_yoghurt.js"
import Traditional_Snacks_English from "./pages/english_traditional_snacks.js"
import Beverages_English from "./pages/english_beverages.js"
import Waffles_English from "./pages/english_waffles.js"

import logo from "./pages/portapolis.png";
import cube_logo from "./pages/cube-logo.png"
export default function App() {
    return (
        <Router>
            <div className="title">
                <img src={logo} alt="logo" className="logo" />
            </div>
            <Navbar />
            <Routes>
                <Route path="/el" element={<Navbar_categories_el />} >
                    <Route path="breakfast" element={<Breakfast_Greek />} />
                    <Route path="beer" element={<Beer_Greek />} />
                    <Route path="choco" element={<Choco_Greek />} />
                    <Route path="distillates" element={<Distillates_Greek />} />
                    <Route path="juices" element={<Juices_Greek />} />
                    <Route path="pizza" element={<Pizza_Greek />} />
                    <Route path="soft-drinks" element={<Soft_Drinks_Greek />} />
                    <Route path="spirits" element={<Spirits_Greek />} />
                    <Route path="tea" element={<Tea_Greek />} />
                    <Route path="toast" element={<Toast_Greek />} />
                    <Route path="wine" element={<Wine_Greek />} />
                    <Route path="yoghurt" element={<Yoghurt_Greek />} />
                    <Route path="traditional_snacks" element={<Traditional_Snacks_Greek />} />
                    <Route path="beverages" element={<Beverages_Greek />} />
                    <Route path="coffee" element={<Coffee_Greek />} />
                    <Route path="sweets" element={<Sweets_Greek />} />
                    <Route path="waffles" element={<Waffles_Greek />} />
                </Route>
                <Route path="/en" element={<Navbar_categories_en />} >
                    <Route path="breakfast" element={<Breakfast_English />} />
                    <Route path="beer" element={<Beer_English />} />
                    <Route path="choco" element={<Choco_English />} />
                    <Route path="distillates" element={<Distillates_English />} />
                    <Route path="juices" element={<Juices_English />} />
                    <Route path="pizza" element={<Pizza_English />} />
                    <Route path="soft-drinks" element={<Soft_Drinks_English />} />
                    <Route path="spirits" element={<Spirits_English />} />
                    <Route path="tea" element={<Tea_English />} />
                    <Route path="toast" element={<Toast_English />} />
                    <Route path="wine" element={<Wine_English />} />
                    <Route path="yoghurt" element={<Yoghurt_English />} />
                    <Route path="coffee" element={<Coffee_English />} />
                    <Route path="sweets" element={<Sweets_English />} />
                    <Route path="traditional_snacks" element={<Traditional_Snacks_English />} />
                    <Route path="waffles" element={<Waffles_English />} />
                    <Route path="beverages" element={<Beverages_English />} />
                </Route>
            </Routes>
            <div className="underline_end"></div> 
            <div className="copyrights">
                <a href="https://www.cube-creations.com" target="_blank" rel="noreferrer">
                    <img src={cube_logo} alt="cube-logo" className="cube-logo"/>
                </a>
              <h5>© 2024 Cube Creations. All rights reserved.</h5>
            </div>
        </Router>
    );
}