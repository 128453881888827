const menu = [
  {
    id: 1,
    title: 'Με τυρί & γαλοπούλα',
    category_eng: 'Τόστ',
    price: 3.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
