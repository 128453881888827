const menu = [
  {
    id: 1,
    title: 'Green',
    category_eng: 'Tea',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Black',
    category_eng: 'Tea',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'With flavour',
    category_eng: 'Tea',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Cretan mountain tea',
    category_eng: 'Tea',
    price: 4.20,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 5,
    title: 'Organic mountain tea',
    category_eng: 'Tea',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 6,
    title: 'Detox (Green tea, matte, matca, lemongrass)',
    category_eng: 'Tea',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 7,
    title: 'Red Hearts (Fruit tea, hibiscus, cherry, vanilla, apple, rose petals, raspberry, red sugar hearts)',
    category_eng: 'Tea',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 8,
    title: 'Volcano',
    category_eng: 'Tea',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 9,
    title: '+ alcohol',
    category_eng: 'Tea',
    price: 3.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
