const menu = [
  {
    id: 1,
    title: 'Raki carafe',
    category_eng: 'Distillates',
    price: 8.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Ouzo Varvayanni (200ml)',
    category_eng: 'Distillates',
    price: 9.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Raki with honey',
    category_eng: 'Distillates',
    price: 9.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Meze (olives, pickles, cherry tomato, & fish roe dip)',
    category_eng: 'Distillates',
    price: 3.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
