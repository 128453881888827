const menu = [
  {
    id: 1,
    title: 'Βάφλα με μερέντα και ξηρούς καρπούς',
    category_eng: 'Βάφλες',
    price: 6.90,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Βάφλα με μερέντα και δύο μπάλες παγωτό',
    category_eng: 'Βάφλες',
    price: 9.90,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Βάφλα με μερέντα και φρούτα',
    category_eng: 'Βάφλες',
    price: 6.90,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Μπάλα παγωτό',
    category_eng: 'Βάφλες',
    price: 3.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 5,
    title: '+ σαντυγί',
    category_eng: 'Βάφλες',
    price: 0.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
