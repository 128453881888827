import React from "react";

const Menu_eng = ({ items_eng }) => {
  return (
    <div className="section-center">
      {items_eng.map((item) => {
        const { id, title, img, desc, price, category_eng } = item;
        if (category_eng === 'Breakfast' || category_eng === 'Sweets'){
          if (img ===''){
            return (
              <article key={id} className="menu-item">
              <div className="item-info">
                  <header>
                   <h4>{title}</h4>
                   <h4 className="price">{price}</h4>
                  </header>
                  <p className="item-text">{desc}</p>
              </div>   
              </article>      
            );}
          else{
            return (
              <article key={id} className="menu-item">
                <img src={img} alt={title} className="photo" />
                <div className="item-info">
                  <header>
                    <h4>{title}</h4>
                    <h4 className="price">{price}</h4>
                  </header>
                  <p className="item-text">{desc}</p>
                </div>
              </article>
            );
            }
          }
        else{
          return (
            <div className="item-info-no-photos">
                <h4>{title}</h4>
                <h4 className="price">{price}</h4>
            </div>
          );
        }
      })}
    </div>
  );
};

export default Menu_eng;