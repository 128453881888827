const menu = [
  {
    id: 1,
    title: 'Waffle with choco cream & nuts',
    category_eng: 'Waffles',
    price: 6.90,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Waffle with choco cream & two scoops of ice cream',
    category_eng: 'Waffles',
    price: 9.90,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Waffle with choco cream & fruits',
    category_eng: 'Waffles',
    price: 6.90,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Scoop of ice cream',
    category_eng: 'Waffles',
    price: 3.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 5,
    title: '+ whipped cream',
    category_eng: 'Waffles',
    price: 0.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
