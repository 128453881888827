const menu = [
  {
    id: 1,
    title: 'Μονός espresso',
    category_eng: 'Καφές',
    price: 3.20,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Διπλός espresso',
    category_eng: 'Καφές',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Διπλός espresso Americano',
    category_eng: 'Καφές',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Διπλός espresso Macchiato',
    category_eng: 'Καφές',
    price: 4.20,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 5,
    title: 'Freddo espresso',
    category_eng: 'Καφές',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 6,
    title: 'Μονός cappuccino',
    category_eng: 'Καφές',
    price: 4.30,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 7,
    title: 'Διπλός cappuccino',
    category_eng: 'Καφές',
    price: 4.70,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 8,
    title: 'Latte',
    category_eng: 'Καφές',
    price: 4.80,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 9,
    title: 'Flat white',
    category_eng: 'Καφές',
    price: 4.80,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 10,
    title: 'Freddo cappuccino',
    category_eng: 'Καφές',
    price: 4.70,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 11,
    title: 'Frappuccino',
    category_eng: 'Καφές',
    price: 6.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 12,
    title: 'Mocha',
    category_eng: 'Καφές',
    price: 6.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 13,
    title: 'Mochaccino Affogato',
    category_eng: 'Καφές',
    price: 6.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 14,
    title: 'Matcha latte',
    category_eng: 'Καφές',
    price: 6.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 15,
    title: 'Nes cafe',
    category_eng: 'Καφές',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 16,
    title: 'Frappe',
    category_eng: 'Καφές',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 17,
    title: 'Frappe with ice-cream',
    category_eng: 'Καφές',
    price: 7.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 18,
    title: 'Μονός ελληνικός',
    category_eng: 'Καφές',
    price: 3.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 19,
    title: 'Διπλός ελληνικός',
    category_eng: 'Καφές',
    price: 3.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 20,
    title: 'Φίλτρου κλασσικός',
    category_eng: 'Καφές',
    price: 3.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 21,
    title: 'Φίλτρου με γεύσεις',
    category_eng: 'Καφές',
    price: 4.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
];
export default menu;
