const menu = [
  {
    id: 1,
    title: 'Τσικουδιά καραφάκι',
    category_eng: 'Αποστάγματα',
    price: 8.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Ούζο Βαρβαγιάννη',
    category_eng: 'Αποστάγματα',
    price: 9.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Ρακόμελο',
    category_eng: 'Αποστάγματα',
    price: 9.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Μεζές (ελιές, τουρσί, ντοματίνι, & ταραμοσαλάτα)',
    category_eng: 'Αποστάγματα',
    price: 3.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
