import React, { useState } from "react";
import Menu from "./Menu";
import items from "./data/data_greek_waffles"
import { useNavigate } from "react-router-dom"

const Greek = () => {
  let navigate = useNavigate()
  const [menuItems] = useState(items);

    return (
      <main>
      <section className="menu section">
        <Menu items ={menuItems}/>
      </section>
      <button className="filter-btn" onClick={() => navigate("../")}>Πίσω</button>
    </main>
    );
};

export default Greek;