const menu = [
  {
    id: 1,
    title: 'Coca Cola, light, zero)',
    category_eng: 'Αναψυκτικά',
    price: 3.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Γεράνι (μπυράλ, γκαζόζα, πορτοκαλάδα, λεμονάδα)',
    category_eng: 'Αναψυκτικά',
    price: 6.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Πορτοκαλάδα, Λεμονάδα',
    category_eng: 'Αναψυκτικά',
    price: 3.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Red Bull',
    category_eng: 'Αναψυκτικά',
    price: 3.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 5,
    title: 'Ανθρακούχο Νερό',
    category_eng: 'Αναψυκτικά',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 6,
    title: 'Σόδα',
    category_eng: 'Αναψυκτικά',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 7,
    title: 'Schweppes',
    category_eng: 'Αναψυκτικά',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 8,
    title: 'Arizona Ice Tea Ροδάκινο',
    category_eng: 'Αναψυκτικά',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 9,
    title: 'Arizona Ice Tea Ρόδι',
    category_eng: 'Αναψυκτικά',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 10,
    title: 'Arizona Ice Tea Πράσινο τσάι με μέλι',
    category_eng: 'Αναψυκτικά',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
