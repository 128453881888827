const menu = [
  {
    id: 1,
    title: 'Sandwich',
    category: 'Πρωινό',
    price: 3.00,
    img: '/images/breakfast_1.jpg',
    desc: `Αφράτο panini με γαλοπούλα, τυρί, ντομάτα, μαρούλι και μαγιονέζα.`,
    desc_eng: `Soft panini with turkey, chesse, tomata, gabbage and mayo.`,
  },
  {
    id: 2,
    title: 'Αλμυρή βάφλα',
    category: 'Πρωινό',
    price: 8.90,
    img: '/images/breakfast_2.jpg',
    desc: `Αλμυρή βάφλα με γαλοπούλα, τυρί, τυρί Philadelphia και αυγό.`,
  },
  {
    id: 3,
    title: 'Ομελέτα',
    category: 'Πρωινό',
    price: 8.00,
    img: '/images/breakfast_3.jpg',
    desc: `Κλασσική ομελέτα με 3 αυγά.`,
  },
  {
    id: 4,
    title: 'Pancakes',
    category: 'Πρωινό',
    price: 5.50,
    img: '',
    desc: `Μερέντα και ξηρούς καρπούς \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  },
  {
    id: 5,
    title: 'Pancakes',
    category: 'Πρωινό',
    price: 5.50,
    img: '',
    desc: `Μέλι ή σιρόπι αγαύης και ξηρούς καρπούς \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  },
  {
    id: 6,
    title: 'Pancakes',
    category: 'Πρωινό',
    price: 6.50,
    img: '',
    desc: `Φυστικοβούτυρο, μπανάνα και μέλι ή σιρόπι αγαύης`,
  },
  {
    id: 7,
    title: 'Τορτίγια',
    category: 'Πρωινό',
    price: 8.50,
    img: '',
    desc: `Με ομελέτα, τυρί, γαλοπούλα, ντομάτα και λόλα`,
  },
  {
    id: 8,
    title: 'Τορτίγια',
    category: 'Πρωινό',
    price: 10.00,
    img: '',
    desc: `Με ομελέτα, απάκι, γραβιέρα, ντομάτα και λόλα`,
  },
  {
    id: 9,
    title: 'Ομελέτα',
    category: 'Πρωινό',
    price: 7.00,
    img: '',
    desc: `Με τυρί και γαλοπούλα \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  },
  {
    id: 10,
    title: 'Ομελέτα',
    category: 'Πρωινό',
    price: 9.00,
    img: '',
    desc: `Με απάκι και γραβιέρα \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  },
  {
    id: 11,
    title: 'Ομελέτα',
    category: 'Πρωινό',
    price: 10.00,
    img: '',
    desc: `Αυγά στο φούρνο με ζαμπόν και τυρί \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  },
  {
    id: 12,
    title: 'Αλμυρή βάφλα',
    category: 'Πρωινό',
    price: 8.90,
    img: '',
    desc: `Ζαμπόν, τυρί, αυγό \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  },
  {
    id: 13,
    title: 'Αλμυρή βάφλα',
    category: 'Πρωινό',
    price: 9.50,
    img: '',
    desc: `Τυρί κρέμα, ντοματίνια, ρόκα \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  }
];
export default menu;
