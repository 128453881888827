const menu = [
  {
    id: 1,
    title: 'Μαλοτήρα',
    category_eng: 'Ροφήματα',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Local devine herbs',
    category_eng: 'Ροφήματα',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Σπιτική λεμονάδα',
    category_eng: 'Ροφήματα',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Σπιτική κανελάδα',
    category_eng: 'Ροφήματα',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
