const menu = [
  {
    id: 1,
    title: 'Red Berries (330 ml)',
    category_eng: 'Spirits',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Cider (330ml)',
    category_eng: 'Spirits',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Normal drink',
    category_eng: 'Spirits',
    price: 8.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Metaxa 5^{*}',
    category_eng: 'Spirits',
    price: 9.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 5,
    title: 'Aperol Spritz',
    category_eng: 'Spirits',
    price: 9.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 6,
    title: 'Aperol Spritz with pink grapefruit soda',
    category_eng: 'Spirits',
    price: 9.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 7,
    title: 'Campari Spritz',
    category_eng: 'Spirits',
    price: 9.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 8,
    title: 'Single alcohol shot',
    category_eng: 'Spirits',
    price: 3.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
