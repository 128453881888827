const menu = [
  {
    id: 1,
    title: 'Λευκό',
    category_eng: 'Κρασί',
    price: 6.90,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Κόκκινο',
    category_eng: 'Κρασί',
    price: 6.90,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Ροζέ',
    category_eng: 'Κρασί',
    price: 6.90,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Moscato D Asti',
    category_eng: 'Κρασί',
    price: 7.40,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
