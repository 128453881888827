const menu = [
  {
    id: 1,
    title: 'Carrot cake',
    category_eng: 'Sweets',
    price: 4.50,
    img: '/images/sweet_1.jpg',
    desc: `Carrot cake with creamy cheese.`,
  },
  {
    id: 2,
    title: 'Chocolate soufflé',
    category_eng: 'Sweets',
    price: 4.00,
    img: '/images/sweet_2.jpg',
    desc: `Our classic fluffy chocolate soufflé.`,
  },
  {
    id: 3,
    title: 'Sweet wholegrain bars',
    category_eng: 'Sweets',
    price: 2.20,
    img: '/images/sweet_3.jpg',
    desc: `Wholegrain bars with lemon or white chocolate.`,
  },
  {
    id: 4,
    title: 'Cinnamon roll',
    category_eng: 'Sweets',
    price: 2.50,
    img: '/images/sweet_4.jpg',
    desc: `A classic cinnamon roll with berries.`,
  },
  {
    id: 5,
    title: 'Cupcakes',
    category_eng: 'Sweets',
    price: 2.00,
    img: '/images/sweet_5.jpg',
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 6,
    title: 'Apple pie',
    category: 'Sweets',
    price: 5.90,
    img: '',
    desc: `Cupcakes with vanilla or chocolate. \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  },
  {
    id: 7,
    title: 'Karydopita (pecan pie)',
    category: 'Sweets',
    price: 5.90,
    img: '',
    desc: `Cupcakes with vanilla or chocolate. \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  },
  {
    id: 8,
    title: 'Souflé',
    category: 'Sweets',
    price: 5.90,
    img: '',
    desc: `Cupcakes with vanilla or chocolate. \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  },
  {
    id: 9,
    title: 'Red velvet',
    category: 'Sweets',
    price: 6.90,
    img: '',
    desc: `Cupcakes with vanilla or chocolate. \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  },
  {
    id: 10,
    title: 'Carrot',
    category: 'Sweets',
    price: 6.90,
    img: '',
    desc: `Cupcakes with vanilla or chocolate. \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  }
];
export default menu;
