const menu = [
  {
    id: 1,
    title: 'Carrot cake',
    category: 'Γλυκά',
    price: 4.50,
    img: '/images/sweet_1.jpg',
    desc: `Απολαυστικό carrot cake με τυρί κρέμα.`,
  },
  {
    id: 2,
    title: 'Σουφλέ σοκολάτας',
    category: 'Γλυκά',
    price: 4.00,
    img: '/images/sweet_2.jpg',
    desc: `Κλασσικό ατομικό σουφλέ σοκολάτας.`,
  },
  {
    id: 3,
    title: 'Μπάρες δημητριακών',
    category: 'Γλυκά',
    price: 2.20,
    img: '/images/sweet_3.jpg',
    desc: `Μπάρες δημητριακών με γεύση λεμόνι ή άσπρη σοκολάτα.`,
  },
  {
    id: 4,
    title: 'Cinnamon roll',
    category: 'Γλυκά',
    price: 2.50,
    img: '/images/sweet_4.jpg',
    desc: `Κλασσικό ατομικό cinnamon roll με σταφίδες.`,
  },
  {
    id: 5,
    title: 'Cupcakes',
    category: 'Γλυκά',
    price: 2.00,
    img: '/images/sweet_5.jpg',
    desc: `Αφράτα cupcake με γεύση βανίλια ή σοκολάτα.`,
  },
  {
    id: 6,
    title: 'Μηλόπιτα',
    category_eng: 'Γλυκά',
    price: 5.90,
    img: '',
    desc: ``,
  },
  {
    id: 7,
    title: 'Καρυδόπιτα',
    category_eng: 'Γλυκά',
    price: 5.90,
    img: '',
    desc: `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  },
  {
    id: 8,
    title: 'Σουφλέ',
    category_eng: 'Γλυκά',
    price: 5.90,
    img: '',
    desc: `Cupcakes with vanilla or chocolate. \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  },
  {
    id: 9,
    title: 'Red velvet',
    category_eng: 'Γλυκά',
    price: 6.90,
    img: '',
    desc: `Cupcakes with vanilla or chocolate. \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  },
  {
    id: 10,
    title: 'Carrot',
    category_eng: 'Γλυκά',
    price: 6.90,
    img: '',
    desc: `Cupcakes with vanilla or chocolate. \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  }
];
export default menu;
