import React from "react";
import { Nav, NavLink, NavMenu } from "./NavbarElements";
import { Outlet } from "react-router-dom";
const Navbar_categories_en = () => {
    return (
        <>
            <div className="title">
                <h2>Menu</h2>
                <div className="underline"></div> 
            </div>
            <Nav>
                <NavMenu>
                    <NavLink to="/en/breakfast">
                        Breakfast
                    </NavLink>
                    <NavLink to="/en/sweets">
                        Sweets
                    </NavLink>
                    <NavLink to="/en/coffee">
                        Coffee
                    </NavLink>
                    <NavLink to="/en/choco">
                        Chocolates
                    </NavLink>
                    <NavLink to="/en/tea">
                        Tea
                    </NavLink>
                    <NavLink to="/en/juices">
                        Juices
                    </NavLink>
                    <NavLink to="/en/yoghurt">
                        Yoghurt
                    </NavLink>
                    <NavLink to="/en/toast">
                        Toast
                    </NavLink>
                    <NavLink to="/en/pizza">
                        Pizza
                    </NavLink>
                    <NavLink to="/en/beer">
                        Beers
                    </NavLink>
                    <NavLink to="/en/spirits">
                        Spirits
                    </NavLink>
                    <NavLink to="/en/wine">
                        Wine
                    </NavLink>
                    <NavLink to="/en/distillates">
                        Distillates
                    </NavLink>
                    <NavLink to="/en/soft-drinks">
                        Soft-drinks
                    </NavLink>
                    <NavLink to="/en/traditional_snacks">
                        Traditional Snacks
                    </NavLink>
                    <NavLink to="/en/beverages">
                        Beverages
                    </NavLink>
                    <NavLink to="/en/waffles">
                        Waffles
                    </NavLink>
                </NavMenu>
            </Nav>
            <Outlet />
        </>
    );
};

export default Navbar_categories_en;