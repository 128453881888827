const menu = [
  {
    id: 1,
    title: 'Με φρούτα',
    category_eng: 'Γιαούρτι',
    price: 7.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Με καρύδι & μέλι',
    category_eng: 'Γιαούρτι',
    price: 7.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Με γλυκό του κουταλιού',
    category_eng: 'Γιαούρτι',
    price: 7.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
