const menu = [
  {
    id: 1,
    title: 'Margarita',
    category_eng: 'Pizza',
    price: 9.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Pepperoni',
    category_eng: 'Pizza',
    price: 12.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },  
  {
    id: 3,
    title: 'Turkey',
    category_eng: 'Pizza',
    price: 12.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Carbonara',
    category_eng: 'Pizza',
    price: 14.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
