const menu = [
  {
    id: 1,
    title: 'Πορτοκάλι',
    category_eng: 'Χυμοί',
    price: 4.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Ανάμεικτος',
    category_eng: 'Χυμοί',
    price: 5.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Φρουτοσαλάτα',
    category_eng: 'Χυμοί',
    price: 6.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Φρουτοσαλάτα με παγωτό',
    category_eng: 'Χυμοί',
    price: 9.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
