const menu = [
  {
    id: 1,
    title: 'White',
    category_eng: 'Wines',
    price: 6.90,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Red',
    category_eng: 'Wines',
    price: 6.90,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Rose',
    category_eng: 'Wines',
    price: 6.90,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Moscato D Asti',
    category_eng: 'Wines',
    price: 7.40,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
