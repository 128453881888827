const menu = [
  {
    id: 1,
    title: 'Πράσινο',
    category_eng: 'Τσάι',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Μαύρο',
    category_eng: 'Τσάι',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Με γεύσεις',
    category_eng: 'Τσάι',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Μαλοτήρα',
    category_eng: 'Τσάι',
    price: 4.20,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 5,
    title: 'Βιολογικό τσάι του βουνού',
    category_eng: 'Τσάι',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 6,
    title: 'Detox',
    category_eng: 'Τσάι',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 7,
    title: 'Red Hearts (τσάι φρούτων, ιβίσκος, κεράσι, βανίλια, μήλο, ροδοπέταλα, raspberry, κόκκινες ζαχαρένιες καρδιές',
    category_eng: 'Τσάι',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 8,
    title: 'Ηφαίστειο',
    category_eng: 'Τσάι',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 9,
    title: '+ αλκοόλ',
    category_eng: 'Τσάι',
    price: 3.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
