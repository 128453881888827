const menu = [
  {
    id: 1,
    title: 'With fruits',
    category_eng: 'Yoghurt',
    price: 7.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'With walnuts & honey',
    category_eng: 'Yoghurt',
    price: 7.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'With preserved fruit',
    category_eng: 'Yoghurt',
    price: 7.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
