const menu = [
  {
    id: 1,
    title: 'Sandwich',
    category_eng: 'Breakfast',
    price: 3.00,
    img: '/images/breakfast_1.jpg',
    desc: `Soft panini with turkey, chesse, tomata, gabbage and mayo.`,
  },
  {
    id: 2,
    title: 'Salty waffle',
    category_eng: 'Breakfast',
    price: 8.90,
    img: '/images/breakfast_2.jpg',
    desc: `Salty waffle with turkey, cheese, philadelphia cheese and egg on top.`,
  },
  {
    id: 3,
    title: 'Omelette',
    category_eng: 'Breakfast',
    price: 8.00,
    img: '/images/breakfast_3.jpg',
    desc: `Classic omelette with 3 eggs.`,
  },
  {
    id: 4,
    title: 'Pancakes',
    category_eng: 'Breakfast',
    price: 5.50,
    img: '',
    desc: `Nutelle with nuts \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  },
  {
    id: 5,
    title: 'Pancakes',
    category_eng: 'Breakfast',
    price: 5.50,
    img: '',
    desc: `Honey or agave syrup with nuts \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  },
  {
    id: 6,
    title: 'Pancakes',
    category_eng: 'Breakfast',
    price: 6.50,
    img: '',
    desc: `Peanutbutter, banana with honey or agave syrup`,
  },
  {
    id: 7,
    title: 'Tortilla',
    category_eng: 'Breakfast',
    price: 8.50,
    img: '',
    desc: `With omelet, cheese, turkey, tomato and lola lettuce`,
  },
  {
    id: 8,
    title: 'Tortilla',
    category_eng: 'Breakfast',
    price: 10.00,
    img: '',
    desc: `With omelet, apaki, graviera, tomato and lola lettuce`,
  },
  {
    id: 9,
    title: 'Omelet',
    category_eng: 'Breakfast',
    price: 7.00,
    img: '',
    desc: `With cheese and turkey \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  },
  {
    id: 10,
    title: 'Omelet',
    category_eng: 'Breakfast',
    price: 9.00,
    img: '',
    desc: `With apaki and graviera \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  },
  {
    id: 11,
    title: 'Omelet',
    category_eng: 'Breakfast',
    price: 10.00,
    img: '',
    desc: `Baked eggs with ham and cheese \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  },
  {
    id: 12,
    title: 'Salty waffle',
    category_eng: 'Breakfast',
    price: 8.90,
    img: '',
    desc: `Ham, cheese, fried egg \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  },
  {
    id: 13,
    title: 'Salty waffle',
    category_eng: 'Breakfast',
    price: 9.50,
    img: '',
    desc: `Cream cheese, cherry tomatoes, rocket \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
  }
];
export default menu;
