import React, { useState } from "react";
import Menu from "./Menu_eng"
import items_eng from "./data/data_english_choco"
import { useNavigate } from "react-router-dom"

const English = () => {
  let navigate = useNavigate()
  const [menuItems] = useState(items_eng);

    return (
      <main>
      <section className="menu section">
        <Menu items_eng ={menuItems}/>
      </section>
      <button className="filter-btn" onClick={() => navigate("../")}>Back</button>
    </main>
    );
};

export default English;