
import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  background:  transparent;
  border-color: #c59d5f;
  font-size: 1rem;
  margin: 0 0.5rem;
  letter-spacing: 1px;
  color:  black;
  cursor: pointer;
  transition: all 0.3s linear;
  border-radius: 5.5rem;
  font-family: 'Roboto Slab';
  width: 100px;
`;

export const NavLink = styled(Link)`
  background:  transparent;
  border-color: #c59d5f;
  font-size: 1rem;
  margin-top: -.5rem;
  margin-bottom: .8rem;
  letter-spacing: 1px;
  padding: .7rem 3rem;
  /*color:  hsl(360, 71%, 66%);*/
  color:  black;
  cursor: pointer;
  transition: all 0.3s linear;
  border-radius: 5.5rem;
  font-family: 'Roboto Slab';
  width: 100px; /* all circles in button the same*/
  justify-content: center;
  text-align: center;
  text-decoration: none; // button text not underline
`;

export const Bars = styled(FaBars)`
  background:  transparent;
  border-color: #c59d5f;
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0 0.5rem;
  letter-spacing: 1px;
  padding: .7rem 1rem;
  color:  black;
  cursor: pointer;
  transition: all 0.3s linear;
  border-radius: 5.5rem;
  font-family: 'Roboto Slab';
  width: 100px; /* all circles in button the same*/
  justify-content: center;
  text-align: center;
`;


export const NavMenu = styled.div`
@media screen and (max-width: 600px){
display: grid;
grid-template-columns: 180px 0px;
align-items: center;
background:  transparent;
border-color: #c59d5f;
font-size: 1rem;
margin: 0 -5.2rem;
letter-spacing: 100px;
padding: .7rem 5rem;
color:  black;
cursor: pointer;
transition: all 0.3s linear;
border-radius: 5.5rem;
font-family: 'Roboto Slab';
width: 100px; /* all circles in button the same*/
}

@media screen and (min-width: 600px){
  display: flex;
  align-items: center;
  background:  transparent;
  border-color: #c59d5f;
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0 0.5rem;
  letter-spacing: 100px;
  padding: .7rem 12rem;
  color:  black;
  cursor: pointer;
  transition: all 0.3s linear;
  border-radius: 5.5rem;
  font-family: 'Roboto Slab';
  width: 100px; /* all circles in button the same*/
  }`
;