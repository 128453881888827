const menu = [
  {
    id: 1,
    title: 'Sfakianopita',
    category_eng: 'Snacks',
    price: 4.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Fennel pie',
    category_eng: 'Snacks',
    price: 4.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: '"Xerotigana" Honey comb',
    category_eng: 'Waffles',
    price: 3.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
