const menu = [
  {
    id: 1,
    title: 'Σφακιανόπιτα',
    category_eng: 'Σνακ',
    price: 4.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Μαραθόπιτα',
    category_eng: 'Σνακ',
    price: 4.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Ξεροτήγανα',
    category_eng: 'Σνακ',
    price: 3.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
