import React from "react";
import { Nav, NavLink, NavMenu } from "./NavbarElements";
import { Outlet } from "react-router-dom";
function Navbar_categories_el () {
    return (
        <>
            <div className="title">
                <h2>Μενού</h2>
                <div className="underline"></div> 
            </div>
            <Nav>
                <NavMenu>
                    <NavLink to="/el/breakfast">
                        Πρωινά
                    </NavLink>
                    <NavLink to="/el/sweets">
                        Γλυκά
                    </NavLink>
                    <NavLink to="/el/coffee">
                        Καφές
                    </NavLink>
                    <NavLink to="/el/choco">
                        Σοκολάτα
                    </NavLink>
                    <NavLink to="/el/tea">
                        Τσάι
                    </NavLink>
                    <NavLink to="/el/juices">
                        Φυσικός χυμός
                    </NavLink>
                    <NavLink to="/el/yoghurt">
                        Γιαούρτι
                    </NavLink>
                    <NavLink to="/el/toast">
                        Τόστ
                    </NavLink>
                    <NavLink to="/el/pizza">
                        Πίτσα
                    </NavLink>
                    <NavLink to="/el/beer">
                        Μπύρες
                    </NavLink>
                    <NavLink to="/el/spirits">
                        Αλκοολούχα ποτά
                    </NavLink>
                    <NavLink to="/el/wine">
                        Κρασί
                    </NavLink>
                    <NavLink to="/el/distillates">
                        Αποστάγματα
                    </NavLink>
                    <NavLink to="/el/soft-drinks">
                        Αναψυκτικά
                    </NavLink>
                    <NavLink to="/el/traditional_snacks">
                        Σνακ
                    </NavLink>
                    <NavLink to="/el/beverages">
                        Ροφήματα
                    </NavLink>
                    <NavLink to="/el/waffles">
                        Βάφλες
                    </NavLink>
                </NavMenu>
            </Nav>
            <Outlet />
        </>
    );
};

export default Navbar_categories_el;