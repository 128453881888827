const menu = [
  {
    id: 1,
    title: 'Coca Cola, light, zero)',
    category_eng: 'Soft-drinks',
    price: 3.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Gerani (local product) (beeral, gazoza, orangeade, lemonade)',
    category_eng: 'Soft-drinks',
    price: 6.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Orangeade, Lemonade',
    category_eng: 'Soft-drinks',
    price: 3.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Red Bull',
    category_eng: 'Soft-drinks',
    price: 3.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 5,
    title: 'Sparkling water',
    category_eng: 'Soft-drinks',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 6,
    title: 'Soda water',
    category_eng: 'Soft-drinks',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 7,
    title: 'Schweppes',
    category_eng: 'Soft-drinks',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 8,
    title: 'Arizona Ice Tea Peach',
    category_eng: 'Soft-drinks',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 9,
    title: 'Arizona Ice Tea Pomegranate',
    category_eng: 'Soft-drinks',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 10,
    title: 'Arizona Ice Tea Green tea with honey',
    category_eng: 'Soft-drinks',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
