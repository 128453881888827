const menu = [
  {
    id: 1,
    title: 'With cheese & turkey',
    category_eng: 'Toast',
    price: 3.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
