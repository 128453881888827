const menu = [
  {
    id: 1,
    title: '"Malotira" Local mountain tea',
    category_eng: 'Beverages',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Local devine herbs',
    category_eng: 'Beverages',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Homemade lemonade',
    category_eng: 'Beverages',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Homemade cinnamon beverage',
    category_eng: 'Beverages',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
