const menu = [
  {
    id: 1,
    title: 'Orange juice',
    category_eng: 'Juices',
    price: 4.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Mixed fresh juice',
    category_eng: 'Juices',
    price: 5.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Fruit salad',
    category_eng: 'Juices',
    price: 6.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Fruit salad with ice-cream',
    category_eng: 'Juices',
    price: 9.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
