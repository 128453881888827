const menu = [
  {
    id: 1,
    title: 'Red Berries (330 ml)',
    category_eng: 'Αλκοολούχα Ποτά',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Μηλοκλέφτης (330ml)',
    category_eng: 'Αλκοολούχα Ποτά',
    price: 5.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Απλό ποτό',
    category_eng: 'Αλκοολούχα Ποτά',
    price: 8.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Metaxa 5^{*}',
    category_eng: 'Αλκοολούχα Ποτά',
    price: 9.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 5,
    title: 'Aperol Spritz',
    category_eng: 'Αλκοολούχα Ποτά',
    price: 9.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 6,
    title: 'Aperol Spritz with pink grapefruit soda',
    category_eng: 'Αλκοολούχα Ποτά',
    price: 9.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 7,
    title: 'Campari Spritz',
    category_eng: 'Αλκοολούχα Ποτά',
    price: 9.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 8,
    title: 'Σφηνάκι',
    category_eng: 'Αλκοολούχα Ποτά',
    price: 3.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
