const menu = [
  {
    id: 1,
    title: 'Mythos (330 ml)',
    category_eng: 'Μπύρες',
    price: 4.00,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'Έζα Pilsner (330ml)',
    category_eng: 'Μπύρες',
    price: 4.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Έζα Pilsner (500ml)',
    category_eng: 'Μπύρες',
    price: 5.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Corona (350ml)',
    category_eng: 'Μπύρες',
    price: 5.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 5,
    title: 'Amstel free alcohol (330ml)',
    category_eng: 'Μπύρες',
    price: 4.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
