const menu = [
  {
    id: 1,
    title: 'Classic',
    category_eng: 'Chocolates',
    price: 4.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 2,
    title: 'White',
    category_eng: 'Chocolates',
    price: 4.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 3,
    title: 'Bitter',
    category_eng: 'Chocolates',
    price: 4.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 4,
    title: 'Viennois',
    category_eng: 'Chocolates',
    price: 5.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 5,
    title: 'Ruby',
    category_eng: 'Chocolates',
    price: 5.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  },
  {
    id: 6,
    title: 'Luxury Maroc',
    category_eng: 'Chocolates',
    price: 5.50,
    desc: `Cupcakes with vanilla or chocolate.`,
  }
];
export default menu;
